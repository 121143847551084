import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { onSnapshot, collection, query, orderBy } from "firebase/firestore";
import { db } from "./../config/firebase";
import Header from "./../components/Header";
import Map from "./../components/Map";
import Card from "./../components/Card";

const Home = () => {
  const [requests, setRequests] = React.useState([]);
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    const q = query(collection(db, "requests"), orderBy("date", "desc"));
    onSnapshot(q, collection(db, "requests"), (snapshot) =>
      setRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );
  }, []);

  const filterCard = requests.filter((card) => {
    return (card.title.toLowerCase() + card.status.toLowerCase()).includes(
      search.toLowerCase()
    );
  });

  return (
    <div className="App h-screen overflow-hidden">
      <div className="h-screen bg-gray-50 dark:bg-gray-900">
        <div className="px-2 mx-auto grid">
          <div className="grid gap-3 mb-8 md:grid-cols-[700px,1fr]">
            <div>
              <div className="mt-20 py-4 px-6 flex justify-between items-center ">
                <div className="relative w-4/5 max-w-xl mr-6 focus-within:text-gray-500">
                  <div className="absolute inset-y-0 flex items-center pl-2">
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0  dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-gray-300 focus:outline-none focus:shadow-outline-gray form-input"
                    type="text"
                    placeholder="Αναζήτηση"
                    aria-label="Search"
                  />
                </div>
                <svg
                  className="w-5 h-5 mr-2 -ml-1 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7.75 4H19M7.75 4a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 4h2.25m13.5 6H19m-2.25 0a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 10h11.25m-4.5 6H19M7.75 16a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 16h2.25"
                  />
                </svg>
                <select
                  onChange={(e) => setSearch(e.target.value)}
                  className="block text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-gray-400 focus:outline-none focus:shadow-outline-gray dark:focus:shadow-outline-gray"
                >
                  <option value={""}>Προβολή όλων</option>
                  <option>Καταχωρήθηκε</option>
                  <option>Γνωστοποιήθηκε</option>
                  <option>Σε εξέλιξη</option>
                  <option>Επιλύθηκε</option>
                </select>
              </div>
              <div className="h-screen overflow-y-scroll pb-44">
                {filterCard.map((key) =>
                  key.status === "Νέο αίτημα"
                    ? ""
                    : key.position.map((location) => (
                        <Card
                          key={key.id}
                          id={key.id}
                          title={key.title}
                          address={key.address}
                          description={key.description}
                          date={key.date}
                          status={key.status}
                          photoURL={key.photoURL}
                          lat={location.lat}
                          lng={location.lng}
                          department={key.department}
                          departmentEmail={key.departmentEmail}
                          departmentPhone={key.departmentPhone}
                          serviceManager={key.serviceManager}
                        />
                      ))
                )}
                <Link
                  to="create-request"
                  className="inline-block absolute bottom-10 left-10 px-2 py-2 text-lg font-large leading-5 text-white transition-colors duration-150 bg-purple-600  border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  aria-label="Like"
                >
                  <span className="ml-2 mr-2 mt-2 text-xl" aria-hidden="true">
                    +
                  </span>
                </Link>
              </div>
            </div>
            <div>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
