import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import logo from "./../img/logo-main.png";

const Header = () => {
  const navigate = useNavigate();
  let { user, logOut } = useUserAuth();

  const handleLogOut = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <header>
      <nav className="w-full fixed bg-purple-600 shadow-sm py-2 md:py-4 z-50">
        <div className="w-full px-2 mx-auto flex items-center md:px-6">
          <div className="w-full flex justify-between items-center">
            <Link to="/about" className="font-bold text-xl text-indigo-600">
              <img className="object-cover logo h-2c w-30" src={logo} />
            </Link>
            <div className="items-center md:flex md:flex-row md:ml-auto md:mt-0 header-buttons">
              <Link
                to="/"
                className="p-2 lg:px-4 px-4 text-white text-center border border-transparent  hover:bg-indigo-100 hover:text-indigo-700 transition-colors duration-300"
              >
                Δείτε τα αιτήματα
              </Link>
              {user ? (
                <button
                  onClick={handleLogOut}
                  className="ml-4 px-4 py-2 text-sm font-medium leading-5 text-indigo-700 transition-colors duration-150 bg-indigo-100 border border-transparent focus:outline-none focus:shadow-outline-purple"
                >
                  {(!user || user.emailVerified === false) &&
                  (!user || user.isAnonymous === false)
                    ? "Σύνδεση"
                    : "Αποσύνδεση"}
                </button>
              ) : (
                <button
                  onClick={() => navigate("/login")}
                  className="ml-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  Σύνδεση
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
