import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import placeholer from "../img/placeholder.png";
import MapPopup from "../components/MapPopup";
import placeholder from "./../img/placeholder.png";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const Card = ({
  id,
  title,
  address,
  description,
  date,
  status,
  photoURL,
  lat,
  lng,
  department,
  departmentEmail,
  departmentPhone,
  serviceManager,
}) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const hex = require("string-hex");

  return (
    <>
      <div
        onClick={onOpenModal}
        className="min-w-0 p-2 m-3 bg-white  shadow-sm  dark:bg-gray-800 block-card hover:bg-gray-100 hover:shadow-md cursor-pointer"
      >
        <div className="flex items-centerbg-white  dark:bg-gray-800">
          <img
            className="max-h-10 bg-gray-100  object-cover h-10 w-12c"
            src={!photoURL ? placeholer : photoURL}
          />
          <div className="px-6 w-full">
            <div className="flex justify-between w-100">
              <span
                className={`px-2 py-1 text-xs font-semibold  dark:bg-green-700 dark:text-green-100 leading-tight  
                ${
                  status === "Γνωστοποιήθηκε"
                    ? "text-green-700 bg-green-100"
                    : status === "Καταχωρήθηκε"
                    ? "text-orange-700 bg-orange-100"
                    : status === "Νέο αίτημα"
                    ? "text-red-700 bg-red-100"
                    : status === "Επιλύθηκε"
                    ? "text-gray-700 bg-gray-100"
                    : status === "Σε εξέλιξη"
                    ? "text-gray-700 bg-gray-100"
                    : ""
                }`}
              >
                {status}
              </span>
              <div className="text-xs">{hex(id).slice(0, 8)}</div>
            </div>
            <p className="text-lg mt-4 font-semibold text-gray-700 dark:text-gray-200">
              {title}
            </p>
            <p className="mb-2 mt-2 flex items-center text-sm font-medium text-gray-600 dark:text-gray-400">
              {address ? (
                <svg
                  className="mr-1"
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  color="gray.500"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
              ) : (
                ""
              )}
              {address}
            </p>
            <p className="mb-8 text-xs text-gray-600 dark:text-gray-400">
              {date}
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        className="w-full px-6 py-4 overflow-hidden bg-white  dark:bg-gray-800 sm: sm:m-4 sm:max-w-xxl"
      >
        <div className="bg-custom-inner"></div>
        <div className="grid gap-6 mt-8 md:grid-cols-2">
          <div className="min-w-0  dark:bg-gray-800">
            <div className="bg-white p-6  shadow-md">
              <div className="flex justify-between items-center">
                <span
                  className={`px-2 py-1 text-xs font-semibold  dark:bg-green-700 dark:text-green-100 leading-tight  
                    ${
                      status === "Γνωστοποιήθηκε"
                        ? "text-green-700 bg-green-100"
                        : status === "Καταχωρήθηκε"
                        ? "text-orange-700 bg-orange-100"
                        : status === "Νέο αίτημα"
                        ? "text-red-700 bg-red-100"
                        : status === "Επιλύθηκε"
                        ? "text-gray-700 bg-gray-100"
                        : status === "Σε εξέλιξη"
                        ? "text-gray-700 bg-gray-100"
                        : ""
                    }`}
                >
                  {status}
                </span>
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  {hex(id).slice(0, 8)}
                </p>
              </div>
              <h2 className="mt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                {title}
              </h2>
              <p className="mb-8 flex items-center my-2 text-gray-600 dark:text-gray-400">
                {address ? (
                  <svg
                    className="mr-1"
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    color="gray.500"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                ) : (
                  ""
                )}
                {address}
              </p>
              <p className="text-gray-600 dark:text-gray-400">{description}</p>
            </div>
            <div className="timeline mt-5 bg-white p-6  shadow-md">
              <div className="tl-content tl-content-active">
                <div className="tl-header flex justify-between items-center">
                  <span
                    className={`tl-marker shadow-md ${
                      status === "Γνωστοποιήθηκε"
                        ? "text-green-700 bg-green-100"
                        : status === "Καταχωρήθηκε"
                        ? "text-orange-700 bg-orange-100"
                        : status === "Νέο αίτημα"
                        ? "text-red-700 bg-red-100"
                        : status === "Επιλύθηκε"
                        ? "text-gray-700 bg-gray-100"
                        : status === "Σε εξέλιξη"
                        ? "text-gray-700 bg-gray-100"
                        : ""
                    }`}
                  ></span>
                  <div>
                    <p className="tl-title text-gray-700">{status}</p>
                    <p className="text-xs">
                      {department === "Εσωτερικό"
                        ? ""
                        : `Γνωστοποιήθηκε στον/στην ` + department}
                    </p>
                    <p className="mb-2 tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                      {date}
                    </p>
                  </div>
                  <div>
                    {department != "Εσωτερικό" ? (
                      <p className="tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">Email: </span>
                        {departmentEmail}
                      </p>
                    ) : (
                      ""
                    )}
                    {department != "Εσωτερικό" ? (
                      <p className="tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">Τηλέφωνο: </span>
                        {departmentPhone}
                      </p>
                    ) : (
                      ""
                    )}
                    {department != "Εσωτερικό" ? (
                      <p className="tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">Υπεύθυνος: </span>
                        {serviceManager}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="min-w-0 dark:bg-gray-800">
            <div className="flex justify-center mb-5 shadow-md  bg-gray-100 dark:bg-gray-800">
              <Lightbox
                className="max-h-25  object-cover h-21c w-full"
                image={!photoURL ? placeholder : photoURL}
              />
            </div>
            <div className="map mt-5 shadow-md ">
              <MapPopup lat={lat} lng={lng} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Card;
