import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { onSnapshot, collection } from "firebase/firestore";
import { db } from "./../config/firebase";
import mapStyles from "./../config/StyleMap";
import placeholder from "../img/placeholder.png";
import MapPopup from "../components/MapPopup";
import { Modal } from "react-responsive-modal";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const libraries = ["places"];
const mapContainerStyle = {
  width: "65vw",
  height: "100vh",
};

const center = {
  lat: 41.0698034,
  lng: 24.4700336,
};

const NEW_ZEALAND_BOUNDS = {
  north: -34.36,
  south: -47.35,
  west: 166.28,
  east: -175.81,
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: false,

  // restriction: {
  //     latLngBounds: NEW_ZEALAND_BOUNDS,
  //     strictBounds: false,
  // }
};

const Map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTNlodzBYoyKdEo32y6P5ooX-owI-w0RA",
    libraries,
  });

  const [requests, setRequests] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [popup, setPopup] = React.useState([]);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(
    () =>
      onSnapshot(collection(db, "requests"), (snapshot) =>
        setRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  return (
    <div>
      <GoogleMap
        onClick={() => {
          setSelected(null);
        }}
        mapContainerStyle={mapContainerStyle}
        zoom={9}
        center={center}
        options={options}
      >
        {requests.map((key) =>
          key.status === "Νέο αίτημα"
            ? ""
            : key.position.map((pos) => (
                <Marker
                  key={key.id}
                  position={{ lat: pos.lat, lng: pos.lng }}
                  icon={{
                    url: "https://www.iconarchive.com/download/i103443/paomedia/small-n-flat/map-marker.1024.png",
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 20),
                  }}
                  onClick={() => {
                    setSelected({
                      ...pos,
                      title: key.title,
                      photoURL: key.photoURL,
                      status: key.status,
                    });
                    setPopup({
                      ...pos,
                      title: key.title,
                      photoURL: key.photoURL,
                      status: key.status,
                      description: key.description,
                      date: key.date,
                      department: key.department,
                      departmentEmail: key.departmentEmail,
                      departmentPhone: key.departmentPhone,
                      serviceManager: key.serviceManager,
                    });
                  }}
                />
              ))
        )}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div
              className="info_content hover:cursor-pointer"
              onClick={onOpenModal}
            >
              <p className="px-2 mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                {selected.title}
              </p>
              <img
                src={!selected.photoURL ? placeholder : selected.photoURL}
                className="max-h-10 m-2  shadow-md object-cover h-10 w-12c"
              ></img>
              <div className="absolute bottom-2c left-1c">
                <span
                  className={`px-2 py-1 text-xs font-semibold  dark:bg-green-700 dark:text-green-100 leading-tight  
                                ${
                                  selected.status === "Γνωστοποιήθηκε"
                                    ? "text-green-700 bg-green-100"
                                    : selected.status === "Καταχωρήθηκε"
                                    ? "text-orange-700 bg-orange-100"
                                    : selected.status === "Νέο αίτημα"
                                    ? "text-red-700 bg-red-100"
                                    : selected.status === "Επιλύθηκε"
                                    ? "text-gray-700 bg-gray-100"
                                    : selected.status === "Σε εξέλιξη"
                                    ? "text-gray-700 bg-gray-100"
                                    : ""
                                }`}
                >
                  {selected.status}
                </span>
              </div>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        className="w-full px-6 py-4 overflow-hidden bg-white  dark:bg-gray-800 sm: sm:m-4 sm:max-w-xxl"
      >
        <div className="bg-custom-inner"></div>
        <div className="grid gap-6 mt-8 md:grid-cols-2">
          <div className="min-w-0  dark:bg-gray-800">
            <div className="bg-white p-6  shadow-md">
              <div className="flex justify-between items-center">
                <span
                  className={`px-2 py-1 text-xs font-semibold  dark:bg-green-700 dark:text-green-100 leading-tight  
                            ${
                              popup.status === "Γνωστοποιήθηκε"
                                ? "text-green-700 bg-green-100"
                                : popup.status === "Καταχωρήθηκε"
                                ? "text-orange-700 bg-orange-100"
                                : popup.status === "Νέο αίτημα"
                                ? "text-red-700 bg-red-100"
                                : popup.status === "Επιλύθηκε"
                                ? "text-gray-700 bg-gray-100"
                                : popup.status === "Σε εξέλιξη"
                                ? "text-gray-700 bg-gray-100"
                                : ""
                            }`}
                >
                  {popup.status}
                </span>
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  {popup.date}
                </p>
              </div>
              <h2 className="mt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                {popup.title}
              </h2>
              <p className="mb-8 flex items-center my-2 text-gray-600 dark:text-gray-400">
                {popup.address ? (
                  <svg
                    className="mr-1"
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    color="gray.500"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                ) : (
                  ""
                )}
                {popup.address}
              </p>
              <p className="text-gray-600 dark:text-gray-400">
                {popup.description}
              </p>
            </div>
            <div className="timeline mt-5 bg-white p-6  shadow-md">
              <div className="tl-content tl-content-active">
                <div className="tl-header flex justify-between items-center">
                  <span
                    className={`tl-marker shadow-md ${
                      popup.status === "Γνωστοποιήθηκε"
                        ? "text-green-700 bg-green-100"
                        : popup.status === "Καταχωρήθηκε"
                        ? "text-orange-700 bg-orange-100"
                        : popup.status === "Νέο αίτημα"
                        ? "text-red-700 bg-red-100"
                        : popup.status === "Επιλύθηκε"
                        ? "text-gray-700 bg-gray-100"
                        : popup.status === "Σε εξέλιξη"
                        ? "text-gray-700 bg-gray-100"
                        : ""
                    }`}
                  ></span>
                  <div>
                    <p className="tl-title text-gray-700">{popup.status}</p>
                    <p className="text-xs">
                      {popup.department === "Εσωτερικό"
                        ? ""
                        : `Γνωστοποιήθηκε στον/στην ` + popup.department}
                    </p>
                    <p className="mb-2 tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                      {popup.date}
                    </p>
                  </div>
                  <div>
                    {popup.department != "Εσωτερικό" ? (
                      <p className="tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">Email: </span>
                        {popup.departmentEmail}
                      </p>
                    ) : (
                      ""
                    )}
                    {popup.department != "Εσωτερικό" ? (
                      <p className="tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">Τηλέφωνο: </span>
                        {popup.departmentPhone}
                      </p>
                    ) : (
                      ""
                    )}
                    {popup.department != "Εσωτερικό" ? (
                      <p className="tl-time py-1 text-xs text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">Υπεύθυνος: </span>
                        {popup.serviceManager}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="min-w-0 dark:bg-gray-800">
            <div className="flex justify-center mb-5 shadow-md  bg-gray-100 dark:bg-gray-800">
              <Lightbox
                className="max-h-25  object-cover h-21c w-full"
                image={!popup.photoURL ? placeholder : popup.photoURL}
              />
            </div>
            <div className="map mt-5 shadow-md ">
              <MapPopup lat={popup.lat} lng={popup.lng} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Map;
