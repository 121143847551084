import React from "react";
import { useUserAuth } from "./../../context/UserAuthContext";

const AccountInformation = ({ formData, setFormData }) => {
  let { user } = useUserAuth();

  return (
    <>
      <div className="flex flex-col overflow-y-auto md:flex-row">
        <div className="w-full">
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">
              Ονοματεπώνυμο: *
            </span>
            <input
              required
              type="text"
              name="fullname"
              defaultValue={user.displayName}
              onChange={(event) =>
                setFormData({ ...formData, fullname: event.target.value })
              }
              className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
            />
          </label>
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">
              Τηλέφωνο: *
            </span>
            <input
              required
              type="text"
              name="phone"
              value={user.phone}
              onChange={(event) =>
                setFormData({ ...formData, phone: event.target.value })
              }
              className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
            />
          </label>
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">Email: *</span>
            <input
              type="email"
              name="email"
              defaultValue={user.email}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
              className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default AccountInformation;
