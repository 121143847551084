import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import mapStyles from "./../config/StyleMap";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const center = {
  lat: 41.0698034,
  lng: 24.4700336,
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: false,
};

const MapPopup = ({ date, lat, lng }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTNlodzBYoyKdEo32y6P5ooX-owI-w0RA",
    libraries,
  });
  const [markers, setMarkers] = React.useState([]);
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={18}
      center={{ lat, lng }}
      options={options}
      onLoad={onMapLoad}
    >
      <Marker
        position={{ lat: lat, lng: lng }}
        icon={{
          url: "https://www.iconarchive.com/download/i103443/paomedia/small-n-flat/map-marker.1024.png",
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(20, 20),
        }}
      />
    </GoogleMap>
  );
};

export default MapPopup;
