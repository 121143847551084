import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const ProtectedRoute = ({ children }) => {
  let { user } = useUserAuth();
  if (
    (!user || user.emailVerified === false) &&
    (!user || user.isAnonymous === false)
  ) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
