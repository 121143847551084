import React, { useEffect, useState } from "react";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "./../../config/firebase";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { useUserAuth } from "./../../context/UserAuthContext";
import AccountInformation from "./AccountInformation";
import Description from "./Description";
import Submission from "./Submission";
import MapForm from "./MapForm";

const Form = () => {
  let { user } = useUserAuth();

  const [page, setPage] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const [formData, setFormData] = React.useState({
    fullname: user.displayName,
    phone: "",
    email: user.email,
    title: "",
    description: "",
    photoURL: "",
    position: "",
    address: "",
    status: "Νέο αίτημα",
    date: "",
    timestamp: "",
  });

  useEffect(() => {
    // {setPage((currPage) => currPage = 1)}
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case "paused":
              //console.log('Upload is paused');
              break;
            case "running":
              //console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData((prev) => ({ ...prev, photoURL: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const FormTitles = ["Πληροφορίες λογαριασμού", "Στοιχεία αιτήματος", ""];

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <AccountInformation formData={formData} setFormData={setFormData} />
      );
    } else if (page === 1) {
      return (
        <Description
          formData={formData}
          setFormData={setFormData}
          file={setFile}
          setFile={setFile}
        />
      );
    } else {
      return <Submission formData={formData} setFormData={setFormData} />;
    }
  };

  const handleAddRequest = async () => {
    try {
      const res = await addDoc(collection(db, "requests"), {
        id: "",
        fullname: formData.fullname,
        phone: formData.phone,
        email: formData.email,
        title: formData.title,
        description: formData.description,
        photoURL: formData.photoURL,
        address: formData.address,
        position: formData.position,
        status: formData.status,
        date: new Date().toLocaleString() + "",
        timestamp: serverTimestamp(),
      });
      const docRef = doc(db, "requests", res.id);
      await updateDoc(docRef, {
        id: res.id,
      });
    } catch (err) {
      console.log(err);
    }

    console.log(user);
  };

  return (
    <form id="msform">
      <fieldset>
        <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
          <div className="bg-custom"></div>
          <div className="z-10 flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white  shadow-xl dark:bg-gray-800">
            <div className=" main-form-block flex items-center justify-center p-6 sm:p-12">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  {FormTitles[page]}
                </h1>
                {PageDisplay()}
                <button
                  hidden={page == 0 || page == 2}
                  type="button"
                  name="next"
                  className="mt-4 mr-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  onClick={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  Προηγούμενο
                </button>
                <button
                  disabled={
                    (page === 0 &&
                      // formData.fullname==='' ||
                      // formData.email==='' ||
                      formData.phone === "") ||
                    (page === 1 &&
                      (formData.title === "" ||
                        formData.address === "" ||
                        formData.description === ""))
                  }
                  hidden={page === 2}
                  type="button"
                  name="next"
                  className="mt-4 mr-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple disabled:opacity-25"
                  onClick={() => {
                    if (page === FormTitles.length - 2) {
                      setPage((currPage) => currPage + 1);
                      handleAddRequest();
                    } else {
                      setPage((currPage) => currPage + 1);
                    }
                  }}
                >
                  {page === FormTitles.length - 2 ? "Υποβολή" : "Επόμενο"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default Form;
