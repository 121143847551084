import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import img from "../img/pic3.png";

const ResetPassword = () => {
  return (
    <div class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="bg-custom"></div>
      <div class="z-10 flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white  shadow-xl dark:bg-gray-800">
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:block"
              src={img}
              alt="reset"
            />
          </div>
          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div class="w-full">
              <h1 class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Ενεργοποίηση του λογαριασμού σας
              </h1>
              <p>
                Έχετε λάβει email επιβεβαίωσης στην ηλεκτρονική διεύθυνση που
                καταχωρήσατε, παρακαλούμε προχωρήστε σε επιβεβαίωση του email
                σας για να καταθέσετε το αίτημα σας.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
