import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import img from "../img/Landing-page-pic1.png";
import img2 from "../img/landing-page-pic2.png";

const About = () => {
  return (
    <div className="App  bg-gray-50">
      <div className="z-10 flex-1 max-w-7xl mx-auto overflow-hidden  dark:bg-gray-800">
        <div className="flex flex-col md:mt-40 overflow-y-auto md:flex-row intro">
          <div className="flex items-center md:w-1/2">
            <div className="w-full p-6 intro-inner">
              <h1 className="mb-4 text-5xl font-bold text-gray-700 dark:text-gray-200">
                Ας κάνουμε τον τόπο μας{" "}
                <span className="color-green">καλύτερο!</span>
              </h1>
              <p className="text-lg">
                To «Ένα με τον πολίτη» είναι η υπηρεσία διαχείρισης καθημερινών
                προβλημάτων της  Περιφέρεια Ανατολικής Μακεδονίας Θράκης και σας παρέχει
                λειτουργίες υποβολής αιτημάτων και ενημέρωσης της εξέλιξης τους.
              </p>
              <Link
                to="/create-request"
                className="inline-block mt-10 px-10 py-4 font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              >
                Νέο αίτημα
              </Link>
            </div>
          </div>
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:block"
              src={img}
              alt="reset"
            />
          </div>
        </div>
      </div>

      <div className="z-10 flex-1 py-40 max-w-7xl mx-auto overflow-hidden bg-gray-50 dark:bg-gray-800 section">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full shadow-xl h-full dark:block"
              src={img2}
              alt="reset"
            />
          </div>
          <div className="flex items-center md:w-1/2">
            <div className="w-full p-16">
              <h2 className="mb-4 text-3xl font-bold text-gray-700 dark:text-gray-200">
                Πως λειτουργεί η υπηρεσία:
              </h2>
              <p className="text-lg py-3 flex">
                <svg
                  className="mr-4 w-6 h-6 color-green dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 21"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                  ></path>
                </svg>
                Υποβολή του αιτήματος σας από υπολογιστή ή κινητό
              </p>
              <p className="text-lg py-3 flex ">
                <svg
                  className="mr-4 w-6 h-6 color-green dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 21"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                  ></path>
                </svg>
                Ανάθεση στην εκάστοτε υπεύθυνη υπηρεσία
              </p>
              <p className="text-lg py-3 flex">
                <svg
                  className="mr-4 w-6 h-6 color-green dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 21"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                  ></path>
                </svg>
                Καταγραφή της προόδου
              </p>
              <p className="text-lg py-3 flex">
                <svg
                  className="mr-4 w-6 h-6 color-green dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 21"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                  ></path>
                </svg>
                Επίλυση του προβλήματος
              </p>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer-1 bg-purple-600  py-8 sm:py-12">
        <div className="container mx-auto px-4">
          <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
            <div className="px-4 sm:w-1/6 md:w-1/3 xl:w-1/3">
              <h5 className="text-xl font-bold mb-6 text-white">Επικοινωνία</h5>
              <ul className="list-none footer-links text-white">
                <li className="mb-6 font-thin">
                  Γραφείο Περιφερειάρχη
                  <br />
                  Γ. Κακουλίδη 1, 69132 Κομοτηνή, Ελλάδα
                  <br />
                  Email: periferiarxis@pamth.gov.gr
                </li>
                <li className="mb-6 font-thin">

                </li>
              </ul>
            </div>
            <div className="px-4 sm:w-1/6 md:w-1/3 xl:w-1/3 mt-8 sm:mt-0">
              <h5 className="text-xl font-bold">
                <div className="whitespace mb-6"></div>
              </h5>
              <ul className="list-none footer-links text-white">
                <li className="mb-6 font-thin">
                 
                </li>
                <li className="mb-6 font-thin">
                 
                </li>
              </ul>
            </div>
            <div className="px-4 mt-4 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto">
              <h5 className="text-xl font-bold mb-6 text-white">Χρήσιμα</h5>
              <ul className="list-none footer-links text-white">
                <li className="mb-2">
                  <Link
                    to="https://www.pamth.gov.gr/index.php/el/"
                    className="border-b border-solid font-thin border-transparent"
                  >
                    Περιφέρεια Ανατολικής Μακεδονίας Θράκης
                  </Link>
                </li>
                <li className="mb-2">
                 
                </li>
                <li className="mb-2">
                 
                </li>
                <li className="mb-2">
                  
                </li>
                <li className="mb-2">
                 
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;
