import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import img from "../img/pic3.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleButton from "react-google-button";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { logIn, googleSignIn, guestSignIn } = useUserAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  let { user } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/create-request");
    } catch (err) {
      setError(err.message);
      toast.error("Μη έγκυρο email ή κωδικός πρόσβασης", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      toast.success("Είσοδος με επιτυχία", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
      });
      navigate("/create-request");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGuestLogIn = async (e) => {
    e.preventDefault();
    try {
      await guestSignIn();
      toast.success("Είσοδος με επιτυχία", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
      });
      navigate("/create-request");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="bg-custom"></div>
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white  shadow-xl dark:bg-gray-800 z-10">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-contain w-full h-full dark:hidden"
              src={img}
              alt="login"
            />
          </div>
          <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Είσοδος στην υπηρεσία
              </h1>
              <form onSubmit={handleSubmit}>
                <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Διεύθυνση email*
                  </span>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  />
                </label>
                <label className="block mt-4 text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Κωδικός*
                  </span>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    type="password"
                    autoComplete="on"
                  />
                </label>
                <button
                  type="submit"
                  className="w-full mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent  active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  Σύνδεση
                </button>
              </form>

              {/* <button 
                onClick={handleGuestLogIn}
                className="mt-4 flex items-center justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300  dark:text-gray-400 active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray">
                Συνδεθείτε ως επισκέπτης
              </button> */}

              <hr className="my-8" />
              <GoogleButton onClick={handleGoogleSignIn} />

              <p className="mt-4">
                <Link
                  to="/reset-password"
                  className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                  href=""
                >
                  Ξέχασα τον κωδικό μου
                </Link>
              </p>
              <p className="mt-1">
                <Link
                  to="/signup"
                  className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                  href="./create-account.html"
                >
                  Εγγραφή με το email μου
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
