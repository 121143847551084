import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import imgDone from "./../../img/done.png";

const Submission = ({ formData, setFormData }) => {
  return (
    <div className="grid content-center justify-center">
      <img
        className="w-full max-h-10  object-contain h-10 ml-6"
        src={imgDone}
      />
      <br />
      <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        Λάβαμε την αίτηση σας
      </h2>
      <p className="mt-1 text-center">
        <Link
          to="/"
          className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
          href="./create-account.html"
        >
          Επιστροφή στην αρχική
        </Link>
      </p>
    </div>
  );
};

export default Submission;
