import React from "react";
import MapForm from "./MapForm";
import FormMap from "./FormMap";

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const Description = ({ formData, setFormData, file, setFile }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestion,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 39.6388504, lng: () => 22.4145399 },
      radius: 200 * 1000,
    },
  });

  return (
    <>
      <div className="grid gap-6 mb-8 md:grid-cols-2">
        <div className="min-w-0 bg-white  dark:bg-gray-800">
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">
              Τίτλος αιτήματος: *
            </span>
            <input
              type="text"
              name="title"
              placeholder=""
              value={formData.title}
              onChange={(event) =>
                setFormData({ ...formData, title: event.target.value })
              }
              className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
            />
          </label>
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">
              Περιγραφή αιτήματος: *
            </span>
            <textarea
              type="text"
              name="description"
              placeholder=""
              value={formData.description}
              onChange={(event) =>
                setFormData({ ...formData, description: event.target.value })
              }
              className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-textarea focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
              rows="10"
            ></textarea>
          </label>
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">
              Τοποθεσία: *
            </span>
          </label>
          <label className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400">
              Φωτογραφία: *
            </span>
            <input
              type="file"
              name="photo"
              accept="image/*"
              onChange={(event) => setFile(event.target.files[0])}
              className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
            />
          </label>
        </div>
        <div className="min-w-0  text-white bg-white">
          <FormMap formData={formData} setFormData={setFormData} />
        </div>
      </div>
    </>
  );
};

export default Description;
